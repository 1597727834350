import { createRouter, createWebHistory } from 'vue-router'
// import PublicLayout from '../views/layouts/PublicLayout.vue';
import CabinetLayout from '../views/layouts/CabinetLayout.vue';

// eslint-disable-next-line no-prototype-builtins
const isAuthorized = localStorage.hasOwnProperty('token');
const authGuard = function (to, from, next){
  if(!isAuthorized) next({ name: 'Login' });
  else next()
}

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: () => import('../views/default-pages/LandingPage')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/default-pages/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/default-pages/Register.vue')
  },
  {
    path: '/register/successful',
    name: 'AfterRegistration',
    component: () => import('../views/default-pages/AfterRegistrationPage.vue')
  },
  {
    path: '/confirm-email/:token',
    name: 'ConfirmEmailWithToken',
    component: () => import('../views/default-pages/ConfirmEmailWithToken.vue')
  },
  {
    path: '/forgot-password',
    name: 'Forgot',
    component: () => import('../views/default-pages/ForgotPasswordView.vue')
  },
  {
    path: '/forgot-password/complete',
    name: 'ForgotComplete',
    component: () => import('../views/default-pages/ForgotPasswordComplete.vue')
  },
  {
    path: '/set-password/:token',
    name: 'SetPassword',
    component: () => import('../views/default-pages/SetPasswordWithToken.vue')
  },
  {path: "/:pathMatch(.*)*", component: () => import('../views/default-pages/NotFoundPage.vue')},
  {
    path: '/campaigns',
    component: CabinetLayout,
    children: [
      {
        path: '',
        name: 'CampaignsPage',
        component: () => import('../views/default-pages/CampaignsPage'),
        meta:{
          activeNav: '1'
        }
      },
      {
        path: ':id',
        name: 'CampaignPage',
        component: () => import('../views/default-pages/CampaignInPage'),
        meta:{
          activeNav: '2'
        }
      }
    ],
  },
  {
    path: '/communities',
    component: CabinetLayout,
    children: [
      {
        path: '',
        name: 'CommunitiesPage',
        component: () => import('../views/default-pages/CommunityPage')
      },
      {
        path: ':id',
        name: 'CommunityInPage',
        component: () => import('../views/default-pages/CommunityPage')
      }
    ],
  },
  {
    path: '/cabinet',
    component: CabinetLayout,
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: 'CabinetPage',
        component: () => import('../views/default-pages/CampaignsPage'),
        meta:{
          activeNav: '1'
        }
      },
      {
        path: 'add-campaign',
        name: 'CreateCampaignPage',
        component: () => import('../views/cabinet-pages/CreateCampaignPage'),
        meta:{
          activeNav: '2'
        }
      },
      {
        path: 'my-campaigns',
        name: 'MyCampaigns',
        component: () => import('../views/cabinet-pages/MyCampaignsPage'),
        meta:{
          activeNav: '2'
        }
      },
      {
        path: 'campaigns/events/:id',
        name: 'TasksAnalysis',
        component: () => import('../views/cabinet-pages/CampaingEvents'),
        meta:{
          activeNav: '4'
        }
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('../views/cabinet-pages/ProfilePage'),
        meta:{
          activeNav: '4'
        }
      },
      {
        path: 'my-community',
        name: 'MyCommunityConfig',
        component: () => import('../views/cabinet-pages/MyCommunityConfigPage'),
        meta:{
          activeNav: '6'
        }
      },
      {
        path: 'my-analytics',
        name: 'MyAnalytics',
        component: () => import('../views/cabinet-pages/influencer/AnalyticsPage'),
        meta:{
          activeNav: '7'
        }
      },
    ],
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  document.title = to.meta?.title ?? 'Web3Referral | Web3 Marketing platform';
  let link = document.createElement('link');
  link.setAttribute('rel', 'canonical');
  link.setAttribute('href', location.protocol + '//' + location.host + to.fullPath);
  document.head.appendChild(link);
})

export default router

<template>
  <el-header>
    <el-container>
      <el-row :gutter="30" align="middle">
        <el-col :xs="{span:12}" :sm="{span:6}" :md="{span: 4}" class="zIndex2">
          <a href="/" class="logo"><img src="/referral-assets/logo3.svg" alt=""></a>
        </el-col>
        <el-col :xs="{span: 0}" :sm="{span: 7}" :md="{span: 7}">
        </el-col>
        <el-col :xs="{span:12}" :sm="{span:11}" :md="{span:13}" class="text-right zIndex2">
<!--          <a href="https://t.me/oraclus_reports?utm_source=ooraclus&utm_medium=jjump&utm_campaign=ffixed_btn" target="_blank" class="telegram">-->
<!--            <img src="@/assets/img/landing/telegram.svg" alt="">-->
<!--            <span>Free Daily Reports</span>-->
<!--          </a>-->
          <a href="/campaigns" class="defaultButton mini" v-if="userInfo">
            <span>{{ this.overflowText(userInfo.email) }}</span>
          </a>
          <a href="/login" class="defaultButton mini" v-else>
            <span>Login</span>
          </a>
        </el-col>
      </el-row>
    </el-container>
  </el-header>
</template>

<script>
export default {
  methods:{
    overflowText(text){
      if(text.length > 10){
        text = text.substring(0, 10) + '...';
      }
      return text;
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.get_user_info;
    }
  }
}
</script>

import { createStore } from 'vuex'

// Modules
import AuthModule from './modules/AuthModule'
import CampaignModule from './modules/CampaignModule'
import OptionsModule from './modules/OptionsModule'
import OwnerModule from './modules/CommunityModule'

export default createStore({
  modules: {
      AuthModule,
      CampaignModule,
      OptionsModule,
      OwnerModule
  }
})

<template>
  <router-view/>
</template>

<script>
import {GET_USER_INFO} from '@/store/modules/AuthModule';
export default {
  methods:{
    async getUserInfo(){
      await this.$store.dispatch(GET_USER_INFO);
    }
  },
  created() {
    if(this.currentCredentials.token){
      this.getUserInfo()
    }
    //fix el-select
    let fixElSelect = function () {
      document.querySelectorAll('.el-select.customSelectRemote:hover').forEach(() => {
        let elInput = document.querySelector('.el-select.customSelectRemote:hover input[readonly]');
        if (elInput) {
          elInput.readOnly = false;
          elInput.blur();
          elInput.focus();
        }
      });
    };
    document.addEventListener("focusin", fixElSelect);
    document.addEventListener("click", fixElSelect);
    document.addEventListener("touchstart", fixElSelect);
  },
  computed: {
    currentCredentials() {
      return this.$store.getters.get_credentials;
    }
  }
}
</script>
<script setup>
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/vue'
import { mainnet, arbitrum } from 'viem/chains'

// 1. Get projectId
const projectId = '779af48fc1b03efd155b79b4b09881cf'

// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [mainnet, arbitrum]
const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata
  })

// let wallets = ['b3adea6c0b9172c4a49da31d264a7c4aacd70ea04f6af8e8977ecb974298b13c'];

// 3. Create modal
createWeb3Modal({
  wagmiConfig,
  projectId,
  chains
  // featuredWalletIds: wallets,
  // includeWalletIds: wallets
})
</script>
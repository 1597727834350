import {DefaultAPIInstance} from "@/plugins/axios";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const CREATE_COMMUNITY = "CREATE_COMMUNITY";
export const GET_COMMUNITIES = "GET_COMMUNITIES";
export const GET_COMMUNITY_BY_ID = "GET_COMMUNITY_BY_ID";
export const UPDATE_COMMUNITY_BY_ID = "UPDATE_COMMUNITY_BY_ID";

const state = {}

const actions = {
    [UPLOAD_FILE]: async (store, form) => {
        const { data }  = await DefaultAPIInstance({url:'v1/uploadfile',method:'POST',data:form});
        return data;
    },
    [CREATE_COMMUNITY]: async (store, form) => {
        const { data }  = await DefaultAPIInstance({url:'v1/community/',method:'POST',data:form});
        return data;
    },
    [GET_COMMUNITIES]: async (store, owner_id) => {
        const { data }  = await DefaultAPIInstance({url:'v1/community/?user_id='+owner_id,method:'GET'});
        return data;
    },
    [GET_COMMUNITY_BY_ID]: async (store, owner_id) => {
        const { data }  = await DefaultAPIInstance({url:'v1/community/'+owner_id,method:'GET'});
        return data;
    },
    [UPDATE_COMMUNITY_BY_ID]: async (store, form) => {
        const { data }  = await DefaultAPIInstance({url:'v1/community/'+form.id,method:'PUT',data:form});
        return data;
    },
}

const mutations = {}

const getters = {}
export default {
    state,
    actions,
    mutations,
    getters
}

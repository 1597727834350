<template>
  <div class="newOraclus">
    <el-container class="main-container" id="cabinetMainContainer">
      <HeaderComponent></HeaderComponent>
      <el-container class="in-container">
        <el-aside>
          <AsideComponent />
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
      <el-footer>
        <FooterComponent></FooterComponent>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import AsideComponent from '@/views/cabinet-components/AsideComponent'
import HeaderComponent from '@/views/default-components/HeaderComponent'
import FooterComponent from '@/views/default-components/FooterComponent'

export default {
  name: 'CabinetLayout',
  components:{
    AsideComponent,
    HeaderComponent,
    FooterComponent
  }
};
</script>

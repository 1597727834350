import {DefaultAPIInstance} from "@/plugins/axios";
export const GET_TASKS_CATEGORIES = "GET_TASKS_CATEGORIES";
export const GET_CHAINS = "GET_CHAINS";

const state = {}

const actions = {
    [GET_TASKS_CATEGORIES]: async () => {
        const { data }  = await DefaultAPIInstance({url:'v1/task/task_category',method:'GET'});
        return data;
    },
    [GET_CHAINS]: async (store, filter) => {
        const { data }  = await DefaultAPIInstance({url:'/v1/chain/',method:'GET',params:filter});
        return data;
    },
}

const mutations = {}

const getters = {}
export default {
    state,
    actions,
    mutations,
    getters
}

import {DefaultAPIInstance, LoginAPIInstance} from "@/plugins/axios";

export const ON_LOGIN = "ON_LOGIN";
export const TWITTER_CONNECT = "TWITTER_CONNECT";
export const TWITTER_CONNECT_CALLBACK = "TWITTER_CONNECT_CALLBACK";
// export const ON_GOOGLE_OAUTH = "ON_GOOGLE_OAUTH";
// export const ON_WALLET_AUTH = "ON_WALLET_AUTH";
export const SIWE_GET_NONCE = "SIWE_GET_NONCE";
export const SIWE_VERIFY = "SIWE_VERIFY";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const ON_REGISTER = "ON_REGISTER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const SET_PASSWORD = "SET_PASSWORD";
export const ON_LOGOUT = "ON_LOGOUT";
export const GET_USER_INFO = "GET_USER_INFO";
export const SET_TOKEN = "SET_TOKEN";
export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const DELETE_TOKEN = "DELETE_TOKEN";
export const TOKEN_VERIFICATION = "TOKEN_VERIFICATION";

const state = {
    credentials: {
        token: localStorage.getItem('token') || null,
        refresh_token: localStorage.getItem('refresh_token') || null,
        current_user_id: localStorage.getItem('current_user_id') || null,
        current_user_role: localStorage.getItem('current_user_role') || null
    },
    campaign_referral_code: localStorage.getItem('campaign_referral_code') || null,
    auth_user_info:'',
}

const actions = {
    [ON_LOGIN]: async (store, form) => {
        const { data }  = await LoginAPIInstance({url:'/auth/jwt/login',method:'POST', data:form});
        return data;
    },
    // [ON_GOOGLE_OAUTH]: async (store, form) => {
    //     const { data }  = await SecondAPIInstance({url:'/social_auth/google/',method:'POST', data:form});
    //     return data;
    // },
    [TWITTER_CONNECT]: async () => {
        const { data }  = await DefaultAPIInstance({url:'/auth/associate/twitter/authorize',method:'GET'});
        return data;
    },
    [TWITTER_CONNECT_CALLBACK]: async (store, twitter_params) => {
        const { data }  = await DefaultAPIInstance({url:'https://ref.web3referral.link/auth/associate/twitter/callback?'+twitter_params,method:'GET'});
        return data;
    },
    [SIWE_GET_NONCE]: async () => {
        const { data }  = await LoginAPIInstance({url:'/auth/web3/nonce',method:'GET'});
        return data;
    },
    [SIWE_VERIFY]: async (store, verify_data) => {
        const { data }  = await LoginAPIInstance({url:'/auth/web3/verify',method:'POST', data: verify_data});
        return data;
    },
    [REFRESH_TOKEN]: async (store, refresh_token) => {
        const { data }  = await LoginAPIInstance({url:'/auth/refresh/',method:'POST', data: {"refresh":refresh_token}});
        return data;
    },
    [GET_USER_INFO]:async (store)=>{
        const { data }  = await DefaultAPIInstance({url:'/users/me',method:'GET'});
        store.commit('SET_USER_DETAILS',data)
    },
    [TOKEN_VERIFICATION]:async (store, form)=>{
        const { data }  = await LoginAPIInstance({url:'/auth/verify',method:'POST',data:form});
        return data;
    },
    [ON_REGISTER]:async (store, form)=>{
        const { data }  = await LoginAPIInstance({url:'/auth/register',method:'POST', data:form});
        return data;
    },
    [FORGOT_PASSWORD]:async (store, form)=>{
        const { data }  = await LoginAPIInstance({url:'/auth/forgot-password',method:'POST', data:form});
        return data;
    },
    [CHANGE_PASSWORD]:async (store, form)=>{
        const { data }  = await DefaultAPIInstance({url:'/user/change-password/',method:'POST', data:form});
        return data;
    },
    [SET_PASSWORD]:async (store, form)=>{
        const { data }  = await LoginAPIInstance({url:'/auth/reset-password',method:'POST', data:form});
        return data;
    },
    [ON_LOGOUT]: async (store) => {
        store.commit(DELETE_TOKEN);
        delete DefaultAPIInstance.defaults.headers['authorization'];
        window.location = '/login';
    }
}

const mutations = {
    [SET_TOKEN]: (state, token) => {
        state.credentials.token = token;
        localStorage.setItem('token', token);
    },
    [SET_REFRESH_TOKEN]: (state, token) => {
        state.credentials.refresh_token = token;
        localStorage.setItem('refresh_token', token);
    },
    [SET_USER_DETAILS]: (state, userDetails) => {
        state.auth_user_info = userDetails
        localStorage.setItem('current_user_id', userDetails.id);
        localStorage.setItem('current_user_role', userDetails.preferred_role);
    },
    [DELETE_TOKEN]: (state) => {
        state.credentials.token = null;
        localStorage.removeItem('token');
        state.credentials.refresh_token = null;
        localStorage.removeItem('refresh_token');
        state.credentials.current_user_id = null;
        localStorage.removeItem('current_user_id');
        state.credentials.current_user_role = null;
        localStorage.removeItem('current_user_role');
    }
}

const getters = {
    get_credentials: (state) => state.credentials,
    get_user_info: (state) => state.auth_user_info,
    get_campaign_referral_code: (state) => state.campaign_referral_code,
}
export default {
    state,
    actions,
    mutations,
    getters
}

import {DefaultAPIInstance} from "@/plugins/axios";
export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const GET_CAMPAIGN_BI_ID = "GET_CAMPAIGN_BI_ID";
export const GET_CAMPAIGN_EVENTS_BI_ID = "GET_CAMPAIGN_EVENTS_BI_ID";
export const CAMPAIGN_FORM_DEFAULT = "CAMPAIGN_FORM_DEFAULT";
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const SUBSCRIBE_CAMPAIGN = "SUBSCRIBE_CAMPAIGN";
export const VERIFY_TASK_BY_ID = "VERIFY_TASK_BY_ID";
export const PUBLISH_CAMPAIGN = "PUBLISH_CAMPAIGN";

const state = {
    campaign_form:''
}

const actions = {
    [GET_CAMPAIGNS]: async (store, filter) => {
        const { data }  = await DefaultAPIInstance({url:'/v1/campaign/',method:'GET',params:filter});
        return data;
    },
    [GET_CAMPAIGN_BI_ID]: async (store, campaign_id) => {
        const { data }  = await DefaultAPIInstance({url:'/v1/campaign/'+campaign_id,method:'GET'});
        return data;
    },
    [GET_CAMPAIGN_EVENTS_BI_ID]: async (store, filter) => {
        const { data }  = await DefaultAPIInstance({url:'/v1/campaign/'+filter.campaign_id+'/events',method:'GET',params:filter});
        return data;
    },
    [CREATE_CAMPAIGN]: async (store) => {
        let form = {...store.state.campaign_form};
        const { data }  = await DefaultAPIInstance({url:'/v1/campaign/',method:'POST',data:form});
        return data;
    },
    [SUBSCRIBE_CAMPAIGN]: async (store, campaign_id) => {
        const { data }  = await DefaultAPIInstance({url:'/v1/campaign/'+campaign_id+'/subscribe',method:'POST'});
        return data;
    },
    [VERIFY_TASK_BY_ID]: async (store, task_id) => {
        const { data }  = await DefaultAPIInstance({url:'/v1/task/'+task_id+'/verify',method:'GET'});
        return data;
    },
    [PUBLISH_CAMPAIGN]: async (store, task_id) => {
        const { data }  = await DefaultAPIInstance({url:'/v1/campaign/'+task_id+'/publish',method:'POST'});
        return data;
    },
}

const mutations = {
    [CAMPAIGN_FORM_DEFAULT]: (state) => {
        state.campaign_form = {
            name: '',
            description: '',
            starts_at: '',
            ends_at: '',
            time_zone: '',
            is_draft: true,
            reward_method: 'random',
            reward_type: 'fixed',
            access_type: 'all_users',
            budget: 0,
            winners: 0,
            chain_id: '',
            asset_chain_id: '',
            banner_resource_id: null,
            community_id: null,
            referral_slippage: 0,
            referrable_user_slots: 1,
            tasks: []
        }
    }
}

const getters = {
    get_campaign_form: (state) => state.campaign_form,
}
export default {
    state,
    actions,
    mutations,
    getters
}

<template>
  <div class="asideInBlock">
    <p class="title" @click="this.menuOpened=!this.menuOpened"><i class="bi bi-list" v-if="this.is_mobile"></i>Navigation Bar</p>
    <ul class="menu" :class="this.menuOpened?'opened':''">
      <li>
        <a :class="this.activeNav === '1'?'active':''" href="/campaigns"><i class="bi bi-briefcase"></i><span>All Campaigns</span></a>
      </li>
      <li v-if="userInfo?.preferred_role==='owner'">
        <a :class="this.activeNav === '6'?'active':''" href="/cabinet/my-community"><i class="bi bi-people"></i><span>My Community</span></a>
      </li>
      <li>
        <a :class="this.activeNav === '2'?'active':''" href="/cabinet/my-campaigns"><i class="bi bi-heart"></i><span>My Campaigns</span></a>
      </li>
<!--      <li>-->
<!--        <a :class="this.activeNav === '3'?'active':''" href="/cabinet"><i class="bi bi-wallet"></i><span>My Balance</span></a>-->
<!--      </li>-->
<!--      <li v-if="userInfo?.preferred_role==='influencer'">-->
<!--        <a :class="this.activeNav === '7'?'active':''" href="/cabinet/my-analytics"><i class="bi bi-file-bar-graph"></i><span>Analytics</span></a>-->
<!--      </li>-->
      <li v-if="userInfo">
        <a @click="logoutFunc()"><i class="bi bi-box-arrow-right"></i><span>Logout</span></a>
      </li>
<!--      <li>-->
<!--        <a :class="this.activeNav === '5'?'active':''" href="/cabinet"><i class="bi bi-person"></i><span>Account settings</span></a>-->
<!--      </li>-->
    </ul>
  </div>
</template>
<script>
import {ON_LOGOUT} from '@/store/modules/AuthModule';

export default {
  data:()=>({
    activeNav: '',
    is_mobile: false,
    menuOpened: false
  }),
  watch: {
    '$route'() {
      this.activeNav = this.$route.meta.activeNav;
    }
  },
  methods:{
    logoutFunc() {
      this.$store.dispatch(ON_LOGOUT);
    }
  },
  created() {
    this.activeNav = this.$route.meta.activeNav;
    if(screen.width <= 678){
      this.is_mobile = true;
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.get_user_info;
    }
  }
}
</script>
